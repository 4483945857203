import { NgModule } from '@angular/core';
import { CommonModule, NgClass, NgFor } from '@angular/common';
import { LayoutRoutingModule } from './layout.module.routing';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenusModule } from "@progress/kendo-angular-menu";
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { KendoModule } from '../kendo.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    LayoutRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    KendoModule,
    MenusModule,
    ContextMenuModule,
    MenusModule,
    NgClass, 
    NgFor, 
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
  ]
})
export class LayoutModule { }
