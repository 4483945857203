import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { AuthQuery } from '../state/auth-state/auth.query';

@Injectable()
export class AttachTokenToRequestInterceptor implements HttpInterceptor {
  authToken: string;

  constructor(private authQuery: AuthQuery) {
    this.authQuery.userAuthState$.subscribe((state) => {
      if (state && state.token) this.authToken = state.token;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.authToken) {
      return next.handle(req);
    }
    const modifiedReq = req.clone({
      headers: req.headers.append('Authorization', `Bearer ${this.authToken}`),
    });
    return next.handle(modifiedReq);
  }
}
