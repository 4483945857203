import { Store, StoreConfig } from '@datorama/akita';

import { Injectable } from '@angular/core';

export interface CategoriesState {
  categories: any;
}

export function createInitialState(): CategoriesState {
  return {
    categories: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Categories', resettable: true })
export class CategoriesStore extends Store<CategoriesState> {
  constructor() {
    super(createInitialState());
  }
}
