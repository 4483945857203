import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { LayoutModule } from './layout/layout.module';
// import { LottieModule } from 'ngx-lottie';
// import player from 'lottie-web';
import { isPlatformServer } from '@angular/common';
import { Constants } from './shared/constants/constants';
import { CoreModule } from './core/core.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';

import { MenuModule } from '@progress/kendo-angular-menu';
import { GridModule } from '@progress/kendo-angular-grid';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { OneTapComponent } from './shared/components/one-tap/one-tap.component';

@NgModule({
  declarations: [
    AppComponent,
    OneTapComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    LayoutModule,
    AkitaNgRouterStoreModule,
    BrowserAnimationsModule,
    CoreModule,
    DateInputsModule,
    LabelModule,
    MenuModule,
    GridModule,
    UploadsModule,
  ],
  providers: [
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = !isPlatformServer(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
    if (isPlatformServer(platformId)) {
      Constants.IS_SERVER_SIDE = true;
    } else {
      Constants.IS_SERVER_SIDE = false;
    }
  }
 }
