import { Component, effect } from '@angular/core';
import { ProgressBarComponent } from '@progress/kendo-angular-progressbar';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-profile-status',
  standalone: true,
  imports: [ProgressBarComponent],
  templateUrl: './profile-status.component.html',
  styleUrl: './profile-status.component.scss'
})
export class ProfileStatusComponent {
  profileCompletionProgress: number = 0;

  constructor (private profileService: ProfileService) {

    effect(() => {
      this.profileCompletionProgress = this.profileService.getProfileCompletionProgress();
    });
  }
}
