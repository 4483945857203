export const menuLinks: any[] = [
  {
    text: "Categories",
    items: [
      // {
      //   text: "Industry Domain",
      //   items: [
      //     {
      //       text: "Oil & Gas",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Energy & Utilities",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Aerospace",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Chemical & Pharmaceutical",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Automotive",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Mechanics & Turbomachinery",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Marine",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Rail & Transport",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Electronics & Instrumentation",
      //       url: "/categories"
      //     },
      //     {
      //       text: "HVACR",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Medical Instruments",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Telecommunication",
      //       url: "/categories"
      //     },
      //   ],
      // },
      // {
      //   text: "Engineering Discipline",
      //   items: [
      //     {
      //       text: "Energy & Utilities",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Aerospace",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Chemical & Pharmaceutical",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Automotive",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Mechanics & Turbomachinery",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Marine",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Rail & Transport",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Electronics & Instrumentation",
      //       url: "/categories"
      //     },
      //     {
      //       text: "HVACR",
      //       url: "/categories"
      //     },
      //     {
      //       text: "Medical Instruments",
      //       url: "/categories"
      //     },
      //   ],
      // },
      // {
      //   text: "Industry Certifications",
      //   items: [{
      //     text: "HVAC Certificaitons",
      //     url: "/categories"
      //   }, {
      //     text: "PMP",
      //     url: "/categories"
      //   }],
      // },
    ],
  },
  // {
  //   text: "You can earn",
  //   url: "/"
  // },
  // {
  //   text: "Grow your business",
  //   url: "/"
  // },
  {
    text: "Help",
    url: "/help"
  },
];
