<div class="profile-status-container k-p-5">
  <h3 class="profile-status-title title-xs font-weight-700 font-color-light k-mb-2">Profile completion</h3>
  <p class="body-sm profile-completion-text k-mb-4 k-w-11/12">Your profile is {{profileCompletionProgress}}% complete.
    Want to finish it now?</p>

  <div class="profile-completion-progress-bar k-d-flex k-justify-content-between k-align-items-center k-gap-3">
    <kendo-progressbar [value]="profileCompletionProgress" [label]="false" />
    <p class="body-sm font-weight-600 font-color-light line-height-12">{{profileCompletionProgress}}%</p>
  </div>
</div>
