<section class="profile-wrapper k-d-flex" [class.is-sidebar-visible]="isSidebarVisible">
  <aside class="profile-sidebar-wrapper">

    <app-profile-status></app-profile-status>

    <nav class="profile-sidebar k-mt-10">
      <ul class="profile-sidebar-list k-d-flex k-flex-column k-gap-2">
        @for (sidebarItem of profileSidebarLinks; track $index) {
        <li class="profile-sidebar-item">
          <a [routerLink]="sidebarItem.url" [href]="sidebarItem.url" routerLinkActive="is-active"
            (click)="toggleSidebar()" class="sidebar-link k-p-4 k-d-flex k-gap-3 k-align-items-flex-start">
            <span class="link-icon k-d-flex k-justify-content-center k-align-items-center">
              @if (sidebarItem.title === 'Basic profile') {
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.0001 11.6211C6.69932 11.6211 3.91016 13.4178 3.91016 15.5444C3.91016 18.2944 8.49347 18.2944 10.0001 18.2944C12.6151 18.2944 16.0918 18.0111 16.0918 15.5619C16.0918 13.4253 13.3018 11.6211 10.0001 11.6211Z"
                  fill="#ED7D31" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.9978 9.81174H10.0253C11.1045 9.8084 12.1178 9.38424 12.8778 8.6184C13.6386 7.85258 14.0553 6.83675 14.0511 5.75925C14.0511 3.52341 12.2336 1.70508 9.9978 1.70508C7.76281 1.70508 5.94531 3.52341 5.94531 5.75925C5.94531 7.99341 7.76281 9.81174 9.9978 9.81174Z"
                  fill="#ED7D31" />
              </svg>
              }
              @if (sidebarItem.title === 'Advanced profile') {
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.0195 9.85479H9.99836C8.27651 9.85479 6.8763 8.45394 6.8763 6.73273C6.8763 5.01023 8.27651 3.60938 9.99836 3.60938C11.7209 3.60938 13.1211 5.01023 13.1211 6.73273C13.1243 7.56284 12.8033 8.34545 12.2171 8.93544C11.6316 9.52544 10.8509 9.85222 10.0195 9.85479ZM5.30859 14.2724C5.30859 12.634 7.45738 11.2499 10.0004 11.2499C12.544 11.2499 14.6934 12.6398 14.6934 14.2859C14.6934 16.1727 12.015 16.391 10.0004 16.391C8.83961 16.391 5.30859 16.391 5.30859 14.2724Z"
                  fill="#969696" />
              </svg>
              }
              @if (sidebarItem.title === 'Company profile') {
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.49076 1.84624C5.95609 1.68583 5.41797 2.08621 5.41797 2.64442V18.3344H8.33464V15.4177C8.33464 14.9575 8.70772 14.5844 9.16797 14.5844H10.8346C11.2949 14.5844 11.668 14.9575 11.668 15.4177V18.3344H14.5846V4.89442C14.5846 4.52642 14.3432 4.20198 13.9907 4.09624L6.49076 1.84624ZM8.33464 6.66775C8.10452 6.66775 7.91797 6.8543 7.91797 7.08442V7.91775C7.91797 8.14787 8.10452 8.33442 8.33464 8.33442H9.16797C9.39805 8.33442 9.58464 8.14787 9.58464 7.91775V7.08442C9.58464 6.8543 9.39805 6.66775 9.16797 6.66775H8.33464ZM7.91797 9.58442C7.91797 9.35433 8.10452 9.16775 8.33464 9.16775H9.16797C9.39805 9.16775 9.58464 9.35433 9.58464 9.58442V10.4177C9.58464 10.6478 9.39805 10.8344 9.16797 10.8344H8.33464C8.10452 10.8344 7.91797 10.6478 7.91797 10.4177V9.58442ZM8.33464 11.6677C8.10452 11.6677 7.91797 11.8543 7.91797 12.0844V12.9177C7.91797 13.1478 8.10452 13.3344 8.33464 13.3344H9.16797C9.39805 13.3344 9.58464 13.1478 9.58464 12.9177V12.0844C9.58464 11.8543 9.39805 11.6677 9.16797 11.6677H8.33464ZM10.418 7.08442C10.418 6.8543 10.6046 6.66775 10.8346 6.66775H11.668C11.8981 6.66775 12.0846 6.8543 12.0846 7.08442V7.91775C12.0846 8.14787 11.8981 8.33442 11.668 8.33442H10.8346C10.6046 8.33442 10.418 8.14787 10.418 7.91775V7.08442ZM10.8346 9.16775C10.6046 9.16775 10.418 9.35433 10.418 9.58442V10.4177C10.418 10.6478 10.6046 10.8344 10.8346 10.8344H11.668C11.8981 10.8344 12.0846 10.6478 12.0846 10.4177V9.58442C12.0846 9.35433 11.8981 9.16775 11.668 9.16775H10.8346ZM10.418 12.0844C10.418 11.8543 10.6046 11.6677 10.8346 11.6677H11.668C11.8981 11.6677 12.0846 11.8543 12.0846 12.0844V12.9177C12.0846 13.1478 11.8981 13.3344 11.668 13.3344H10.8346C10.6046 13.3344 10.418 13.1478 10.418 12.9177V12.0844Z"
                  fill="#969696" />
                <path
                  d="M4.58464 18.3346V4.16797H3.33464C2.8744 4.16797 2.5013 4.54107 2.5013 5.0013V17.5013H2.08464C1.85452 17.5013 1.66797 17.6879 1.66797 17.918C1.66797 18.1481 1.85452 18.3346 2.08464 18.3346H4.58464Z"
                  fill="#969696" />
                <path
                  d="M17.918 18.3345H15.418V7.31885L17.0814 8.26939C17.3411 8.4178 17.5013 8.69388 17.5013 8.99296V17.5011H17.918C18.1481 17.5011 18.3346 17.6877 18.3346 17.9178C18.3346 18.1479 18.1481 18.3345 17.918 18.3345Z"
                  fill="#969696" />
                <path d="M10.8346 18.3346V15.418H9.16797V18.3346H10.8346Z" fill="#969696" />
              </svg>
              }
              @if (sidebarItem.title === 'Profile settings') {
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M17.2056 11.2725L16.8768 11.0825C16.4856 10.8569 16.2518 10.4519 16.2518 10C16.2518 9.54813 16.4856 9.14312 16.8768 8.9175L17.2056 8.7275C18.1031 8.20875 18.41 7.06375 17.8918 6.16625L17.2668 5.08375C16.75 4.18812 15.6006 3.88062 14.7056 4.3975L14.3768 4.58688C13.9856 4.81313 13.5175 4.81313 13.1268 4.58688C12.7356 4.36063 12.5018 3.95625 12.5018 3.50437V3.125C12.5018 2.09125 11.6606 1.25 10.6268 1.25H9.37684C8.34309 1.25 7.50184 2.09125 7.50184 3.125V3.505C7.50184 3.95687 7.26809 4.36125 6.87684 4.5875C6.48559 4.81313 6.01809 4.81375 5.62684 4.5875L5.29809 4.3975C4.40309 3.88062 3.25371 4.18812 2.73621 5.08375L2.11121 6.16625C1.59309 7.06375 1.89996 8.20937 2.79746 8.7275L3.12684 8.9175C3.51809 9.14312 3.75184 9.54813 3.75184 10C3.75184 10.4519 3.51809 10.8569 3.12684 11.0825L2.79809 11.2725C1.90059 11.7906 1.59371 12.9363 2.11184 13.8338L2.73684 14.9162C3.25434 15.8119 4.40371 16.1194 5.29809 15.6025L5.62684 15.4131C6.01809 15.1862 6.48559 15.1875 6.87684 15.4131C7.26809 15.6394 7.50184 16.0437 7.50184 16.4956V16.875C7.50184 17.9088 8.34309 18.75 9.37684 18.75H10.6268C11.6606 18.75 12.5018 17.9088 12.5018 16.875V16.495C12.5018 16.0431 12.7356 15.6388 13.1268 15.4125C13.5175 15.1869 13.9856 15.1862 14.3768 15.4125L14.7056 15.6025C15.6006 16.1188 16.75 15.8112 17.2668 14.9162L17.8918 13.8338C18.41 12.9363 18.1031 11.7906 17.2056 11.2725ZM10.0018 13.125C8.27871 13.125 6.87684 11.7231 6.87684 10C6.87684 8.27688 8.27871 6.875 10.0018 6.875C11.725 6.875 13.1268 8.27688 13.1268 10C13.1268 11.7231 11.725 13.125 10.0018 13.125Z"
                  fill="#969696" />
              </svg>
              }
            </span>
            <div class="link-content">
              <div class="k-d-flex k-gap-1.5">
                <h3 class="link-content-title title-xs font-weight-700">{{sidebarItem.title}}</h3>
                @if (sidebarItem.completed) {
                <span class="link-status k-ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.2501 0C14.0818 0 18.0002 3.9184 18.0002 8.7501C18.0002 13.5818 14.0818 17.5002 9.2501 17.5002C4.4184 17.5002 0.5 13.5818 0.5 8.7501C0.5 3.9184 4.4184 0 9.2501 0ZM7.43303 11.5922L5.29079 9.44818C4.92583 9.083 4.92575 8.4875 5.29079 8.12239C5.65591 7.75735 6.25406 7.75964 6.61652 8.12239L8.12678 9.63384L11.8838 5.87681C12.2489 5.5117 12.8445 5.5117 13.2095 5.87681C13.5746 6.24185 13.5741 6.83793 13.2095 7.20254L8.78857 11.6235C8.42398 11.9881 7.82789 11.9886 7.46285 11.6235C7.45259 11.6132 7.4427 11.6028 7.43303 11.5922Z"
                      fill="#00BA00" />
                  </svg>
                </span>
                }
              </div>
              <p class="body-sm font-color-secondary k-mt-1">{{sidebarItem.description}}</p>
            </div>
            <span class="link-goto-icon k-d-flex k-align-items-center k-justify-content-center k-my-auto k-ml-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M6 12L10 8L6 4" stroke="#333F50" stroke-width="1.33333" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </a>
        </li>
        }
      </ul>
    </nav>
  </aside>

  <div class="profile-content-wrapper">
    <div class="profile-content">
      <button (click)="toggleSidebar()" kendoButton size="small" fillMode="flat"
        class="profile-menu-toggle-btn kendo-icon-btn" aria-label="Toggle profile sidebar menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M19 11.8333H5M5 11.8333L10.8333 17.6667M5 11.8333L10.8333 6" stroke="#333F50" stroke-width="1.66667"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
