<footer class="app-footer bg-secondary">

  <div class="container">
    <div class="footer-top k-d-flex k-flex-wrap k-gap-4 k-justify-content-between">
      <div class="footer-top-left">
        <a routerLink="/" class="app-logo k-d-flex k-mb-8" style="min-width: 200px;">
          <img [src]="aufaitAssetsRoot + '/images/app-logo.png'" style="filter: brightness(1000%) saturate(0%);" alt="Company logo" loading="lazy" width="124" height="41">
        </a>

        <div class="footer-subscribe-wrapper k-d-flex k-flex-column k-gap-3">
          <p class="body-md footer-text">Subscribe to our news updates</p>
          <form class="single-input-form k-d-flex" #subscribeForm="ngForm">
            <input type="text" class="form-control" id="subEmailField" [(ngModel)]="subEmailField" name="subEmailField"
              placeholder="Enter email address">
            <button class="form-action-btn" type="button" kendoButton themeColor="primary"
              size="small">Subscribe</button>
          </form>
        </div>

        <div class="footer-social-links k-d-flex k-flex-column k-gap-5 k-mt-12">
          <h3 class="social-links-title title-xs footer-title font-color-light font-weight-700">Join us</h3>
          <ul class="social-links-lists k-d-flex k-gap-2">
            <li class="social-link-item">
              <a href="https://www.linkedin.com/company/everyeng" target="_blank" class="social-link" aria-label="Visit our LinkedIn page">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M2.66645 4.44447C3.64829 4.44447 4.44423 3.64853 4.44423 2.66669C4.44423 1.68485 3.64829 0.888916 2.66645 0.888916C1.68461 0.888916 0.888672 1.68485 0.888672 2.66669C0.888672 3.64853 1.68461 4.44447 2.66645 4.44447Z"
                    fill="white" />
                  <path
                    d="M4.14793 5.62976H1.18497C1.02141 5.62976 0.888672 5.7625 0.888672 5.92606V14.8149C0.888672 14.9785 1.02141 15.1112 1.18497 15.1112H4.14793C4.31149 15.1112 4.44423 14.9785 4.44423 14.8149V5.92606C4.44423 5.7625 4.31149 5.62976 4.14793 5.62976Z"
                    fill="white" />
                  <path
                    d="M12.9758 5.22144C11.7094 4.78766 10.1254 5.1687 9.17547 5.85196C9.14288 5.72455 9.02673 5.62974 8.88865 5.62974H5.92569C5.76214 5.62974 5.62939 5.76248 5.62939 5.92603V14.8149C5.62939 14.9785 5.76214 15.1112 5.92569 15.1112H8.88865C9.05221 15.1112 9.18495 14.9785 9.18495 14.8149V8.42677C9.66376 8.01433 10.2807 7.88277 10.7855 8.09729C11.275 8.30411 11.5553 8.809 11.5553 9.48159V14.8149C11.5553 14.9785 11.6881 15.1112 11.8516 15.1112H14.8146C14.9781 15.1112 15.1109 14.9785 15.1109 14.8149V8.88485C15.0771 6.44989 13.9316 5.54855 12.9758 5.22144Z"
                    fill="white" />
                </svg>
              </a>
            </li>
            <li class="social-link-item">
              <a href="https://www.facebook.com/EveryEng-112790990466070/" target="_blank" class="social-link" aria-label="Visit our Facebook page">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                  <path
                    d="M3.25588 14.5833L3.23565 8.36108H0.588867V5.69442H3.23565V3.91664C3.23565 1.51735 4.71036 0.361084 6.83474 0.361084C7.85233 0.361084 8.72691 0.437413 8.98178 0.471528V2.97892L7.50841 2.9796C6.35307 2.9796 6.12936 3.53273 6.12936 4.34441V5.69442H9.41149L8.52923 8.36108H6.12935V14.5833H3.25588Z"
                    fill="#EDEDED" />
                </svg>
              </a>
            </li>
            <li class="social-link-item">
              <a href="https://www.instagram.com/_everyeng_/?hl=en" target="_blank" class="social-link" aria-label="Visit our Instagram page">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                  <path
                    d="M11.6844 4.67468C12.1557 4.67468 12.5377 4.29263 12.5377 3.82135C12.5377 3.35007 12.1557 2.96802 11.6844 2.96802C11.2131 2.96802 10.8311 3.35007 10.8311 3.82135C10.8311 4.29263 11.2131 4.67468 11.6844 4.67468Z"
                    fill="#EDEDED" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.44434 7.47218C4.44434 9.43307 6.039 11.0277 7.99989 11.0277C9.96078 11.0277 11.5554 9.43307 11.5554 7.47218C11.5554 5.51129 9.96078 3.91663 7.99989 3.91663C6.039 3.91663 4.44434 5.51129 4.44434 7.47218ZM6.22211 7.47224C6.22211 6.49179 7.01945 5.69446 7.99989 5.69446C8.98034 5.69446 9.77767 6.49179 9.77767 7.47224C9.77767 8.45268 8.98034 9.25001 7.99989 9.25001C7.01945 9.25001 6.22211 8.45268 6.22211 7.47224Z"
                    fill="#EDEDED" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.44423 14.5833H11.5553C13.3829 14.5833 15.1109 12.8553 15.1109 11.0278V3.91664C15.1109 2.08908 13.3829 0.361084 11.5553 0.361084H4.44423C2.61667 0.361084 0.888672 2.08908 0.888672 3.91664V11.0278C0.888672 12.8553 2.61667 14.5833 4.44423 14.5833ZM2.66656 3.91668C2.66656 3.08557 3.61322 2.1389 4.44434 2.1389H11.5554C12.3866 2.1389 13.3332 3.08557 13.3332 3.91668V11.0278C13.3332 11.8589 12.3866 12.8056 11.5554 12.8056H4.44434C3.59722 12.8056 2.66656 11.8749 2.66656 11.0278V3.91668Z"
                    fill="#EDEDED" />
                </svg>
              </a>
            </li>
            <li class="social-link-item">
              <a href="https://www.youtube.com/channel/UCOS-RJ72xM4snFvfFqb0udg" target="_blank" class="social-link" aria-label="Visit our YouTube page">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.0889 0.638118C17.864 0.843275 18.4745 1.44454 18.6825 2.20655C19.0687 3.59736 19.0506 6.49709 19.0506 6.49709C19.0506 6.49709 19.0479 9.38084 18.6734 10.7708C18.4627 11.5319 17.8495 12.1323 17.0754 12.3365C15.659 12.7007 9.99291 12.6944 9.99291 12.6944C9.99291 12.6944 4.34215 12.6882 2.91138 12.3063C2.13539 12.1012 1.52491 11.4999 1.3169 10.7388C0.946092 9.36219 0.949708 6.46334 0.949708 6.46334C0.949708 6.46334 0.953327 3.57871 1.32685 2.1879C1.53667 1.42677 2.16433 0.812191 2.92404 0.60881C4.34125 0.24379 10.0065 0.250007 10.0065 0.250007C10.0065 0.250007 15.6726 0.256224 17.0889 0.638118ZM8.19729 3.80351L8.19276 9.13227L12.9066 6.47233L8.19729 3.80351Z"
                    fill="#EDEDED" />
                </svg>
              </a>
            </li>
            <li class="social-link-item">
              <a href="https://t.me/everyeng" target="_blank" class="social-link" aria-label="Visit our Telegram page">
                <svg fill="white" width="20px" height="18px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><title>Telegram icon</title><path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z"/></svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-top-right k-d-flex k-flex-wrap k-gap-6">
        <div class="footer-links-wrapper k-d-flex k-flex-column k-gap-4">
          <h4 class="title-xs footer-title font-weight-700 font-color-light k-mt-3 k-mb-2">Company</h4>
          <ul class="footer-links-list k-d-flex k-flex-column k-gap-3">
            <li class="footer-link-item">
              <a class="footer-link" href="/">Home</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/policies/about-us">About Us</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/policies/privacy-policy">Privacy Policy</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/policies/terms">Term & Condition</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/policies/conduct">Code of Conduct </a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/help">Help</a>
            </li>
          </ul>
        </div>
        <div class="footer-links-wrapper k-d-flex k-flex-column k-gap-4">
          <h4 class="title-xs footer-title font-weight-700 font-color-light k-mt-3 k-mb-2">Opportunity</h4>
          <ul class="footer-links-list k-d-flex k-flex-column k-gap-3">
            <li class="footer-link-item">
              <a class="footer-link" [href]="getSearchURL(EveryEngDomain.Knowledge)">Technical Courses</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" [href]="getSearchURL(EveryEngDomain.Mentor)">Soft-skills Trainings</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" [href]="getSearchURL(EveryEngDomain.Seminar)">Seminar & Conferences</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" [href]="getSearchURL(EveryEngDomain.Story)">Article & Blogs</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Community & Consultation</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Projects for Freelancer</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Internship Options</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Jobs in Engineering</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Digital & AI Tools</a>
            </li>
            <li class="footer-link-item">
              <a class="footer-link" href="/coming-soon">Outsource Engineering & Hiring Services</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-bottom k-d-flex k-align-items-center k-justify-content-between k-mt-10 k-pt-6">
      <p class="body-sm footer-copy-text">EveryEng &#64;2025. All rights reserved.</p>
      <div class="footer-bottom-right k-d-flex k-gap-1.5 k-align-items-center">
        <div class="startup-india k-d-flex">
          <img [src]="aufaitAssetsRoot + '/images/startup-initiative.webp'" alt="Startup India" loading="lazy" width="180" height="43">
        </div>
        <div class="microsoft-startup k-d-flex">
          <img [src]="aufaitAssetsRoot + '/images/mIcrosoft-for-startups.webp'" alt="Microsoft for startups" loading="lazy" width="70"
            height="52">
        </div>
      </div>
    </div>
  </div>

</footer>
