import {
  HttpErrorResponse,  
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class UnAuthorizedResponseInterceptor implements HttpInterceptor {    
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ){
    return next.handle(req).pipe(
      tap((apiError) => {
        if (
          apiError instanceof HttpErrorResponse &&
          apiError.error &&
          apiError.status
        ) {
          if (apiError.status !== 403) 
            return;
          //this.authService.logoutUser();         
        }
      })
    );
  }
}
