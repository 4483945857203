import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './guards/single-import.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiErrorResponseInterceptor } from './interceptors/api-error.interceptor';
import { AttachTokenToRequestInterceptor } from './interceptors/token-interceptor';
import { UnAuthenticatedResponseInterceptor } from './interceptors/unauthenticated-response.interceptor';
import { UnAuthorizedResponseInterceptor } from './interceptors/unauthorized-response.interceptor';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AttachTokenToRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnAuthenticatedResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnAuthorizedResponseInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
