import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { AuthService } from 'src/app/core/state/auth-state/auth.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../constants/constants';

@Component({
  selector: 'app-one-tap',
  templateUrl: './one-tap.component.html',
  styleUrls: ['./one-tap.component.scss']
})
export class OneTapComponent {
  environment = environment
  authSubscription: Subscription;
  isLoggedIn: boolean;
  constructor(
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private ngZone: NgZone,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.isUserLoggedIn$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn
        if(!Constants.IS_SERVER_SIDE){
          if (!isLoggedIn || ((typeof window !== "undefined") && location.pathname === ApplicationRoutes.auth.googleLogin)) {
            // @ts-ignore
            window.onGoogleLibraryLoad = () => {  
              // @ts-ignore
              google.accounts.id.initialize({
                // client_id: '335422918527-fd2d9vpim8fpvbcgbv19aiv98hjmo7c5.apps.googleusercontent.com',
                client_id: environment.googleOptions.client_id,
                callback: this.googleResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: false,
              })
              // @ts-ignore
              google.accounts!.id.renderButton( document!.getElementById('loginBtn')!, { theme: 'outline', size: 'large', width: 200 } )
              // @ts-ignore
              google.accounts.id.prompt(); 
            }
          }else{
            // @ts-ignore
            window.onGoogleLibraryLoad = () => {  
              // @ts-ignore
              google.accounts.id.initialize({
                // client_id: '335422918527-fd2d9vpim8fpvbcgbv19aiv98hjmo7c5.apps.googleusercontent.com',
                client_id: environment.googleOptions.client_id,
                callback: this.googleResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: false,
              })
              // @ts-ignore
              google.accounts!.id.renderButton( document!.getElementById('loginBtn')!, { theme: 'outline', size: 'large', width: 200 } )
              // @ts-ignore
              google.accounts.id.cancel();
            }          
          }
        }
      }
    );
  }

  async googleResponse(response: any) {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {  
      // @ts-ignore
      google.accounts.id.initialize({
        // client_id: '335422918527-fd2d9vpim8fpvbcgbv19aiv98hjmo7c5.apps.googleusercontent.com',
        client_id: environment.googleOptions.client_id,
        callback: this.googleResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: false,
        
      })
      // @ts-ignore
      google.accounts!.id.renderButton( document!.getElementById('loginBtn')!, { theme: 'outline', size: 'large', width: 200 } )
      // @ts-ignore
      google.accounts.id.cancel();
    }          
    this.redirectLogin(response.credential)
    // your logic goes here
  }
  
  ngAfterViewInit() {
    const script1 = this._renderer2.createElement('script');
    script1.src = `https://accounts.google.com/gsi/client`;
    script1.async = `true`; 
    script1.defer = `true`; 
    this._renderer2.appendChild(this._document.body, script1);
  }
  
  redirectLogin(credential: any){
    this.ngZone.run(() => {
      let url = `${environment.googleOptions.redirect_uri}`
      this.router.navigate([`/auth/signin-google`],{
        queryParams: {
          code: credential,
          isFromOneTap: true 
        }
      })
    });    
    
  }
}
