<section class="app-layout k-d-flex k-flex-column">
  <app-header></app-header>
  <app-one-tap></app-one-tap>
  <main #mainSection class="app-main-content">
    <router-outlet></router-outlet>
  </main>

  <!-- Lazy loaded sections -->
  @defer (on viewport(mainSection)) {
  <app-footer></app-footer>
  }
</section>
