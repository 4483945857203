import { CategoriesState, CategoriesStore } from "./categories.store";
import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class CategoriesQuery extends Query<CategoriesState>{
  public categoriesState$: Observable<CategoriesState>
  // public categoriesState$ = this.select((state) => state.categories)
  constructor(protected override store: CategoriesStore) {
    super(store);
    this.categoriesState$ = this.select((state) => state.categories)
  }
}
