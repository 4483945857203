import { catchError, tap } from 'rxjs/operators';
import { CategoriesStore } from './categories.store';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { throwError } from 'rxjs';
import { ErrorMessage } from 'src/app/core/model/api-error';

@Injectable({ providedIn: 'root' })

export class CategoriesService {
  constructor(
      private http: HttpClient,
      private categoriesStore: CategoriesStore
  ) {}

  //fetch categories data
  async getCategoriesListData() {
    this.categoriesStore.setLoading(true);
    try {
      await this.http
        .get(apiOf.lookUps.lookupTree)
        .pipe(
          tap((data: any) => {
            this.updateState(data);
            this.categoriesStore.setLoading(false);
          }),
          catchError((error: ErrorMessage) => {
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) { }
    this.categoriesStore.setLoading(false);
  }

  //update store
  async updateState(categories: any) {
    this.categoriesStore.update((state) => {
      return {
        ...state,
        categories: categories,
      };
    });
  }
}
