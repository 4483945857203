import { Component } from '@angular/core';
import { ProfileStatusComponent } from './profile-status/profile-status.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ButtonComponent } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, ProfileStatusComponent, ButtonComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {

  profileSidebarLinks: any = [
    {
      title: 'Basic profile',
      description: 'All your essential details at a glance for quick access.',
      url: '/profile-html/basic',
      completed: true,
    },
    {
      title: 'Advanced profile',
      description: 'Expand your profile with additional information',
      url: '/profile-html/advanced',
      completed: false,
    },
    {
      title: 'Company profile',
      description: 'Manage your all business identity and details',
      url: '/profile-html/company',
      completed: false,
    },
    {
      title: 'Profile settings',
      description: 'Customize and control your profile preferences',
      url: '/profile-html/settings',
      completed: false,
    }
  ];

  isSidebarVisible: boolean = false;

  toggleSidebar (): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

}
