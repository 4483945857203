import { Component} from '@angular/core';
import { AuthService } from './core/state/auth-state/auth.service';
import { Constants } from './shared/constants/constants';
import { GoogleAnalyticsService } from './shared/services/googleAnalytics.service';
import { NavigationEnd, Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.tryAutoLogin();
    if(!Constants.IS_SERVER_SIDE){
      this.googleAnalyticsService.initialize();
      // Head Start
      var scriptHead = document.createElement('script');
      scriptHead.setAttribute('defer', "true")
      scriptHead.setAttribute('fetchpriority', "low")
      scriptHead.id = "truendoAutoBlock"
      scriptHead.type = 'text/javascript';
      scriptHead.src = 'https://cdn.priv.center/pc/truendo_cmp.pid.js';
      scriptHead.setAttribute('data-siteid', "a64d9fae-5d97-4a2f-8a33-94d478862da4")
      document.querySelector('head')?.appendChild(scriptHead);
      // Head End
      this.router.events.subscribe((router: any)=>{
        if(router instanceof NavigationEnd){
          this.updateCanonicalUrl((typeof window !== "undefined") && location.href)
        }
      })
    }
  }
  
  updateCanonicalUrl(url: any){
    const head = document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
}
