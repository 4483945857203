import { Component } from '@angular/core';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { EveryEngDomain } from 'src/app/shared/constants/everyeng-domains.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  aufaitAssetsRoot = ApplicationRoutes.aufaitAssetsRoot;
  subEmailField: string = '';

  EveryEngDomain = EveryEngDomain;

   getSearchURL(EEdomain: EveryEngDomain): string {
      if(EEdomain == EveryEngDomain.Projects || EEdomain == EveryEngDomain.Community || EEdomain == EveryEngDomain.EngineeringJobs || EEdomain == EveryEngDomain.Internship) {
        return `/coming-soon`;
      }
      return `/search?EEdomains=${EEdomain}`;
    }
}
