import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorMessage } from '../model/api-error';

@Injectable()
export class ApiErrorResponseInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((apiError) => {
        if (
          apiError instanceof HttpErrorResponse &&
          apiError.error &&
          apiError.status
        ) {
          if (apiError.status !== 401 && apiError.status !== 403) {
            const customError: ErrorMessage = {
              type: apiError.error.type,
              title: apiError.error.title || 'Oops! Something went wrong.',
              status: apiError.status,
              detail: apiError.error.detail,
              errors: flattenErrorsCollection(apiError.error.errors ?? {}),
              instance: apiError.error.instance,
            };
            return throwError(customError);
          }
        }
        if(apiError.error){
          var unKnowError: ErrorMessage = {
            type: apiError.error.type,
            title: apiError.error.title || 'Oops! Something went wrong.',
            status: apiError.status,
            detail: apiError.error.detail,
            errors: flattenErrorsCollection(apiError.error.errors),
            instance: apiError.error.instance,
          };
        }else{
          //some unknown error occurred.
          var unKnowError: ErrorMessage = {
            type: 'http://unknown.error/',
            title:
              "It's not you. We are facing some issue. Please try again later.",
            status: 0,
            instance: null,
            detail: null,
            errors: [],
          };
        }
        return throwError(unKnowError);
      })
    );
  }
}

const flattenErrorsCollection = (errors: {
  [key: string]: string[];
}): string[] => {
  const errorPerProp = Object.values(errors);
  if (errorPerProp && errorPerProp.length)
    return errorPerProp.reduce((prev, current) => prev.concat(current), []);
  return ["It's not you. We are facing some issue. Please try again later."];
};
