import { persistState, PersistStateSelectFn } from '@datorama/akita';

const selectToken: PersistStateSelectFn<any> = (state) => ({
  __everyeng__eng_auth: state.__everyeng__eng_auth,
});
selectToken.storeName = 'identity';

//akita persist state setting
const storage = persistState({ select: [selectToken], include: ['identity'] });
export const AkitaPersistanceProvider = [
  { provide: 'persistStorage', useValue: storage },
];
