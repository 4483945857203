<header class="app-header" [ngClass]="{'menu-active': isMenuVisible}">
  <div class="container k-d-flex k-flex-wrap k-align-items-center k-justify-content-between">
    <a routerLink="/" class="app-logo k-d-flex">
      <img [src]="aufaitAssetsRoot + '/images/app-logo.webp'" alt="Company logo" loading="eager" width="123" height="42">
    </a>
    <div class="header-menu-container k-d-flex k-flex k-flex-wrap k-align-items-center">

      @defer (when isScreenTablet) {
      <nav class="header-menu-panel k-mr-auto k-ml-13">
        <kendo-menu class="header-menu" [items]="menuLinks"></kendo-menu>
      </nav>
      }

      <!-- <noscript> -->
        <!-- <nav class="header-menu-panel k-mr-auto k-ml-13">
          <ul class="panelbar-menu">
            <li *ngFor="let menu of menuLinks">
              <a [routerLink]="menu.url">{{ menu.text }}</a>
              <ul *ngIf="menu.items">
                <li *ngFor="let menuLevel1 of menu.items">
                  <a [routerLink]="menuLevel1.url">{{ menuLevel1.text }}</a>
                  <ul *ngIf="menuLevel1.items">
                    <li *ngFor="let menuLevel2 of menuLevel1.items">
                      <a [routerLink]="menuLevel2.url">{{ menuLevel2.text }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav> -->
      <!-- </noscript> -->

      @defer (when isScreenMobile) {
      <form class="single-input-form header-search-form k-d-flex k-ml-auto" #headerSearchForm="ngForm">
        <span class="search-icon k-d-flex k-align-items-center k-justify-content-center k-align-self-center k-ml-3">
          <img [src]="aufaitAssetsRoot + '/images/icon/header-search-icon.svg'" alt="Search icon" width="20" height="20">
        </span>
        <input type="text" class="form-control" id="headerSearchField" [(ngModel)]="headerSearchField" (keyup.enter)="doSearch()"
          name="Search form" placeholder="Search">
      </form>

      <a *ngIf="isLoggedIn" [href]="'/user/cart'">
        <div class="action k-mx-6">
          <img [src]="aufaitAssetsRoot + '/images/icon/shopping-cart.png'" alt="">
          <span class="k-menu-link-text">Cart</span>
        </div>
      </a>

      <a *ngIf="isLoggedIn" [href]="'/product/create/learn'">
        <div class="action k-mx-6">
          <img [src]="aufaitAssetsRoot + '/images/icon/add.png'" alt="">
          <span class="k-menu-link-text">Post</span>
        </div>
      </a>
      
      <div *ngIf="isLoggedIn" class="profile-container k-mx-6">
        <div *ngIf="isLoggedIn" class="profile-container k-justify-items-center coursor-pointer" routerLink="/profile">
          <div class="avatar-card"
            [ngClass]="{'image-empty': !profileUrl, 'avatar-xs': size === 'xs', 'avatar-sm': size === 'sm', 'avatar-lg': size === 'lg'}">
            @if (profileUrl) {
            <img [src]="profileUrl" [alt]="profileName" loading="lazy" width="60" height="60">
            } @else {
            <span class="avatar-icon k-d-flex">
              <img [src]="aufaitAssetsRoot + '/images/icon/avatar-empty-icon.svg'" alt="Avatar icon" width="33" height="33">
            </span>
            }
          </div>
          <!-- {{profileName}} -->
        </div>
        <nav class="header-menu-panel">
          <kendo-menu (select)="selectMenu($event)" class="header-menu" [items]="profileItems"></kendo-menu>
        </nav>
      </div>
      <!-- <kendo-panelbar *ngIf="isLoggedIn; else guestButtons">
        <kendo-panelbar-item [ngClass]="{'panelbar-header': menu.items}" *ngFor="let menu of menuLinks"
          [title]="menu.text" [routerLink]="menu.url">
          <kendo-panelbar-item [ngClass]="{'panelbar-header': menuLevel1.items}" *ngFor="let menuLevel1 of menu.items"
            [title]="menuLevel1.text" [routerLink]="menuLevel1.url">
            <kendo-panelbar-item [ngClass]="{'panelbar-header': menuLevel2.items}"
              *ngFor="let menuLevel2 of menuLevel1.items" [title]="menuLevel2.text" [routerLink]="menuLevel2.url">
            </kendo-panelbar-item>
          </kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar> -->
      
      <ng-container *ngIf="!isLoggedIn">
        <button kendoButton size="small" fillMode="flat" class="k-ml-3" routerLink="/auth/login">
          Log in
        </button>
        <button kendoButton size="small" themeColor="primary" class="k-ml-1" routerLink="/auth/signup">
          Join now
        </button>
      </ng-container>
      }
    </div>

    <!-- Menu toggle -->
    <button #responsiveMenu (click)="toggleMenu()" kendoButton size="small" fillMode="flat"
      class="header-menu-toggle-btn kendo-icon-btn k-ml-3" aria-label="Toggle menu">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        <path
          d="M25 19.4877H3C2.448 19.4877 2 19.0551 2 18.5222C2 17.9892 2.448 17.5566 3 17.5566H25C25.552 17.5566 26 17.9892 26 18.5222C26 19.0551 25.552 19.4877 25 19.4877Z"
          fill="#333F50" />
        <path
          d="M25 12.0853H3C2.448 12.0853 2 11.6528 2 11.1198C2 10.5868 2.448 10.1543 3 10.1543H25C25.552 10.1543 26 10.5868 26 11.1198C26 11.6528 25.552 12.0853 25 12.0853Z"
          fill="#333F50" />
      </svg>
    </button>
  </div>
  <div class="resource-cards-container k-d-flex k-gap-4 k-pl-9">
    @for (resource of resourcesData; track resource.id) {
    <div class="resource-card k-w-full k-d-flex k-align-items-center k-cursor-pointer" (click)="routeToSearch(resource.id)">
      <div class="resource-icon-box k-d-flex k-justify-content-center k-align-items-center" [style.background]="resource.background" style="border-radius: 100px; width: 30px; min-height: 30px;">
        <p *ngIf="resource.count > 0" class="resource-card-subtitle font-weight-700">{{ resource.count }}</p>
        <img *ngIf="resource.count === 0" class="resource-icon" [src]="resource.icon" [alt]="resource.title" loading="lazy" width="36" height="36">
      </div>
      <div class="resource-card-content k-d-flex k-flex-column k-gap-0.5">
        <p class="body-sm font-weight-600" [innerHTML]="resource.title"></p>
        <!-- <p *ngIf="resource.id > -1" class="resource-card-subtitle font-color-secondary font-weight-500">{{ resource.count > 0 ? resource.count : 'coming soon' }}</p> -->
        <p *ngIf="resource.id === -1" class="resource-card-subtitle font-color-secondary font-weight-500">{{ 'Manage all things' }}</p>
      </div>
    </div>
    }
  </div>
</header>

<!-- Responsive menu -->
@defer (on interaction(responsiveMenu)) {
<nav class="header-responsive-panel k-d-flex k-flex-column" [ngClass]="{'menu-active': isMenuVisible}">
  <div class="responsive-panel-header k-d-flex k-align-items-center k-justify-content-between k-mb-11">
    <a routerLink="/" class="app-logo k-d-flex">
      <img [src]="aufaitAssetsRoot + '/images/app-logo.webp'" alt="Company logo" loading="lazy" width="123" height="42">
    </a>
    <button (click)="toggleMenu()" kendoButton size="small" fillMode="flat"
      class="header-menu-toggle-btn kendo-icon-btn k-ml-auto">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path d="M13 1.5L1 13.5M1 1.5L13 13.5" stroke="#0B0B0B" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>

  </div>

  <form class="single-input-form header-search-form k-d-flex k-mb-8" #headerSearchForm="ngForm">
    <span class="search-icon k-d-flex k-align-items-center k-justify-content-center k-align-self-center k-ml-3">
      <img [src]="aufaitAssetsRoot + '/images/icon/header-search-icon.svg'" alt="Search icon" width="20" height="20">
    </span>
    <input type="text" class="form-control" id="headerSearchField2" (keyup.enter)="doSearch()" [(ngModel)]="headerSearchField" name="Search form"
      placeholder="Search">
  </form>

    <div class="profile-container coursor-pointer" *ngIf="isLoggedIn">
      <div class="avatar-card"
        [ngClass]="{'image-empty': !profileUrl, 'avatar-xs': size === 'xs', 'avatar-sm': size === 'sm', 'avatar-lg': size === 'lg'}">
        @if (profileUrl) {
        <img [src]="profileUrl" [alt]="profileName" loading="lazy" width="60" height="60">
        }
      </div>

      {{profileName}}

      <a [href]="'/user/cart'">
        <div class="action k-my-3">
          <img [src]="aufaitAssetsRoot + '/images/icon/shopping-cart.png'" alt="">
          <span class="k-menu-link-text">Cart</span>
        </div>
      </a>
    
      <a [href]="'/product/create/learn'">
        <div class="action k-my-3">
          <img [src]="aufaitAssetsRoot + '/images/icon/add.png'" alt="">
          <span class="k-menu-link-text">Post</span>
        </div>
      </a>
    </div>

  <div class="header-responsive-menu k-mb-5">

    <div class="k-d-flex k-justify-content-center k-align-items-center k-gap-6">
      <button *ngIf="!isLoggedIn" kendoButton size="small" fillMode="flat" class="k-ml-3" routerLink="/auth/login">
        Log in
      </button>
      <button *ngIf="!isLoggedIn" kendoButton size="small" themeColor="primary" class="k-ml-1" routerLink="/auth/signup">
        Join now
      </button>
    </div>

    <kendo-panelbar>
      <kendo-panelbar-item [ngClass]="{'panelbar-header': menu.items}" *ngFor="let menu of menuLinks"
        [title]="menu.text" [routerLink]="menu.url">
        <kendo-panelbar-item [ngClass]="{'panelbar-header': menuLevel1.items}" *ngFor="let menuLevel1 of menu.items"
          [title]="menuLevel1.text" [routerLink]="menuLevel1.url">
          <kendo-panelbar-item [ngClass]="{'panelbar-header': menuLevel2.items}"
            *ngFor="let menuLevel2 of menuLevel1.items" [title]="menuLevel2.text" [routerLink]="menuLevel2.url">
          </kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar-item>
    </kendo-panelbar>

    <kendo-panelbar *ngIf="isLoggedIn">
      <kendo-panelbar-item (click)="menu.text == 'Logout' && onUserLogout()" [ngClass]="{'panelbar-header': menu.items}" *ngFor="let menu of profileItems[0].items" [title]="menu.text" [routerLink]="menu.url"></kendo-panelbar-item> 
    </kendo-panelbar>
  </div>
</nav>
}
