/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AkitaPersistanceProvider } from './app/auth/providers/auth-persistance.provider';


document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic(AkitaPersistanceProvider).bootstrapModule(AppModule)
.catch(err => console.error(err));
});
