import { Component } from '@angular/core';
import { CommonModule, NgClass, NgFor } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MenuComponent, MenuItem } from '@progress/kendo-angular-menu';
import { Button } from '@progress/kendo-angular-buttons';
import { FormsModule } from "@angular/forms";
import { menuLinks } from "./header-menu-items";
import { MediaMatcher } from '@angular/cdk/layout';
import { filter } from 'rxjs/internal/operators/filter';
import { Constants } from '../../shared/constants/constants';
import { EveryEngDomain } from '../../shared/constants/everyeng-domains.enum';
import { CategoriesQuery } from '../state/categories/categories.query';
import { CategoriesService } from '../state/categories/categories.service';
import { catchError, Observable, Subscription, tap, throwError } from 'rxjs';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { EveryEngUserHubService } from 'src/app/core/services/everyeng-user-hub.service';
import { IconsModule } from '@progress/kendo-angular-icons';
import { MemberProfileInfoModel } from 'src/app/pages/profile/models/member-profile-info.model';
import { AuthService } from 'src/app/core/state/auth-state/auth.service';
import { MemberProfileInfoQuery } from 'src/app/pages/profile/state/member-profile-info/member-profile-info.query';
import { CompressedImageSrcPipe } from 'src/app/shared/pipes/compressed-image-src.pipe';
import { HttpClient } from '@angular/common/http';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { ErrorMessage } from 'src/app/core/model/api-error';
import { SeoSocialShareConfig } from 'src/app/configs/seo-social-share.config';
import { SeoSocialShareService } from 'src/app/core/services/seo/seo-social-share.service';

@Component({
  selector: 'app-header',
  standalone: false,
  // imports: [NgClass, NgFor, RouterLink, MenuComponent, Button, FormsModule, KENDO_PANELBAR, HttpClientModule, CommonModule, IconsModule],
  providers: [
    CompressedImageSrcPipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  aufaitAssetsRoot = ApplicationRoutes.aufaitAssetsRoot;
  // assetsRoot = ApplicationRoutes.assetsRoot
  menuLinks: any[] = menuLinks;

  isLoggedIn: boolean = false;

  // Search form
  headerSearchField: string = '';

  isMenuVisible: boolean = false;

  // for lazy loading
  isScreenTablet: boolean = false;
  isScreenMobile: boolean = false;
  categoriesSubscription: Subscription;
  isUserLoggedIn$: Observable<boolean>;
  profileImageState$: Observable<MemberProfileInfoModel | null>;
  authSubscription: Subscription;
  profileUrl: any = ''
  profileName: any = ''
  size: string = 'xs'
  profileItems: any[] = [];

  resourcesData: any[] = [];

  constructor (
    private mediaMatcher: MediaMatcher,
    private router: Router,
    private everyEngUserHubService: EveryEngUserHubService,
    private categoriesQuery: CategoriesQuery,
    private categoriesServices: CategoriesService,
    private authService: AuthService,
    private profileImageQuery: MemberProfileInfoQuery,
    private compressedImageSrcPipe: CompressedImageSrcPipe,
    private http: HttpClient,
    private seoSocialShareService: SeoSocialShareService,
  ) {
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn$;
    this.profileImageState$ = this.profileImageQuery.memberProfileInfoState$;
    const isScreenTablet = this.mediaMatcher.matchMedia('(min-width: 992.98px)');
    this.isScreenTablet = isScreenTablet.matches;
    const isScreenMobile = this.mediaMatcher.matchMedia('(min-width: 768.98px)');
    this.isScreenMobile = isScreenMobile.matches;
    if (!Constants.IS_SERVER_SIDE){
      this.everyEngUserHubService.connect();
      this.router.events.subscribe((router: any)=>{
        if(router instanceof NavigationEnd){
          this.updateCanonicalUrl((typeof window !== "undefined") && location.href)
        }
      })
    }
    // for responsive menu visibility on smaller screen
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((item) => {
      this.isMenuVisible = false;
      this.authSubscription = this.authService.isUserLoggedIn$.subscribe(
        (isLoggedIn) => {
          if (isLoggedIn) {
            if(this.resourcesData.findIndex(x => x.id === -1) === -1) {
              this.resourcesData.push({
                title: 'Dashboard',
                id: -1,
                count: 0,
                position: 0,
                background: '#FEC073',
                icon:
                  this.aufaitAssetsRoot +
                  '/images/icon/projects-freelancing-icon.svg',
              });
              this.resourcesData.sort((a, b) => a.position - b.position);
            }

            var rootSeoConfig = SeoSocialShareConfig['root'];
            this.seoSocialShareService.setData(rootSeoConfig);
            this.isLoggedIn = isLoggedIn;
            this.profileImageState$?.subscribe((item: any)=>{
              if(item){
                const profileUrl = item.profileUrl ? item.profileUrl : this.compressedImageSrcPipe.transform(this.aufaitAssetsRoot + '/images/icon/profile_ic.png');
                this.profileName = item?.displayName ? item?.displayName : (item?.firstName +  item?.lastName)
                this.profileUrl = profileUrl
                this.profileItems = []
                const promises = [
                  this.compressedImageSrcPipe.transform(this.aufaitAssetsRoot + '/images/icon/students-icon.svg'),
                ];
  
                Promise.all(promises).then(([defaultProfileIcon]) => {
                  const profileUrl = item.profileUrl ? item.profileUrl : defaultProfileIcon;
                  this.profileItems.push({
                    text: item?.displayName,
                    iconUrl: profileUrl,
                    url: '/profile',
                    items: [
                      { text: 'Dashboard', url: '/user/dashboard'},
                      { text: "Profile", url: '/profile'},
                      { text: "My Posts", url: "/user/posts"},
                      { text: "My Enrollments", url: "/user/subscriptions"},
                      { text: "Cart", url: "/user/cart"},
                      { text: "Wallet", url: "/user/wallet" },
                      { separator: true },
                      { text: "Logout", click: () => {
                        this.onUserLogout()
                      } },
                    ],
                  });
                }).catch((error) => {
                  console.error('Error resolving icon URLs:', error);
                });
              }
            });
          }
        }
      )
    });
    this.categoriesSubscription = this.categoriesQuery.categoriesState$.subscribe((item: any)=>{
      if(item && item.lookupTree && item.lookupTree.length > 0){
        item.lookupTree.map((item1:any,index:any)=>{
          menuLinks[0].items.push(
            {
              text: item1.lookupType.name,
              items: item1.lookups ? item1.lookups.map((item12:any,index:any)=>{
                return {
                  text: item12.name,
                  url: `category/${item1.lookupType.code}/${item12.code}`
                }
              }) : []
            }
          )
        })
      }
    });  ;
    this.categoriesServices.getCategoriesListData()
    var rootSeoConfig = SeoSocialShareConfig['root'];
    this.seoSocialShareService.setData(rootSeoConfig);
  }

  ngOnInit(): void {
    this.getLearnCount();
    this.getMentorCount();
    this.getSeminarCount();
    this.getKnowCount();
    this.getCommunityCount();
    this.getProjectCount();

    // this.authSubscription = this.authService.isUserLoggedIn$.subscribe(
    //   (isLoggedIn) => {
    //     if (isLoggedIn) {
    //       this.isLoggedIn = isLoggedIn;
    //       this.profileImageState$?.subscribe((item: any)=>{
    //         if(item){
    //           const profileUrl = item.profileUrl ? item.profileUrl : this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/profile_ic.png');
    //           this.profileName = item?.displayName ? item?.displayName : (item?.firstName +  item?.lastName)
    //           this.profileUrl = profileUrl
    //           this.profileItems = []
    //           const promises = [
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/profile_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/tasks_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/subscriptions_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/cart_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/wallet_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/help_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/phone_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/left_ic.png'),
    //             this.compressedImageSrcPipe.transform(this.assetsRoot + '/icons/profile_yellow_ic.png'),
    //           ];

    //           Promise.all(promises).then(([profileIcon, tasksIcon, subscriptionsIcon, cartIcon, walletIcon, helpIcon, contactIcon, logoutIcon, defaultProfileIcon]) => {
    //             const profileUrl = item.profileUrl ? item.profileUrl : defaultProfileIcon;
    //             this.profileItems.push({
    //               text: item?.displayName,
    //               iconUrl: profileUrl,
    //               url: '/profile',
    //               items: [
    //                 { text: 'Dashboard', url: '/user/dashboard'},
    //                 { text: "Profile", url: '/profile'},
    //                 { text: "Posts", url: "/user/posts"},
    //                 { text: "Subscriptions", url: "/user/subscriptions"},
    //                 { text: "Cart", url: "/user/cart"},
    //                 { text: "Wallet", url: "/user/wallet" },
    //                 { separator: true },
    //                 { text: "Logout", click: () => {
    //                   this.onUserLogout()
    //                 } },
    //               ],
    //             });
    //           }).catch((error) => {
    //             console.error('Error resolving icon URLs:', error);
    //           });
    //         }
    //       });
    //     }
    //   }
    // )
  }

  updateCanonicalUrl(url: any){
    const head = document.getElementsByTagName('head')[0];
    var element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  selectMenu($event: any) {
    if($event && $event.item && $event.item.text == 'Logout') {
      this.onUserLogout();
    }
  }
  
  goToCart(){
    this.router.navigate([ApplicationRoutes.shoppingCart.cart]);
  }

  onUserLogout() {
    this.authService.logoutUser();
  }

  toggleMenu () {
    this.isMenuVisible = !this.isMenuVisible;
  }

  doSearch() {
    this.router.navigate(['/search'], {
      queryParams: { EEdomains: EveryEngDomain.Knowledge, searchText: this.headerSearchField },
    });
  }

  public onSelect(event: any): void {
    // Handle menu item selection here
  }

  async getLearnCount() {
      const getLearnCountURL = `${apiOf.learn.getLearnCount}`;
      try {
        await this.http
          .get(getLearnCountURL)
          .pipe(
            tap((data: any) => {
              this.resourcesData.push({
                title: 'Technical</br>Courses',
                id: EveryEngDomain.Knowledge,
                count: data,
                position: 1,
                background: '#B1DDF0',
                icon:
                  this.aufaitAssetsRoot +
                  '/images/icon/technical-courses-icon.svg',
              });
              this.resourcesData.sort((a, b) => a.position - b.position);
            }),
            catchError((apiError: ErrorMessage) => {
              return throwError(apiError);
            })
          )
          .toPromise();
      } catch (error) {}
    }
  
    async getMentorCount() {
      const getMentorCountURL = `${apiOf.mentor.getMentorCount}`;
      try {
        await this.http
          .get(getMentorCountURL)
          .pipe(
            tap((data: any) => {
              this.resourcesData.push({
                title: 'Soft-skill Training Courses',
                id: EveryEngDomain.Mentor,
                count: data,
                position: 2,
                background: '#C5E1B5',
                icon:
                  this.aufaitAssetsRoot +
                  '/images/icon/soft-skill-courses-icon.svg',
              });
              this.resourcesData.sort((a, b) => a.position - b.position);
            }),
            catchError((apiError: ErrorMessage) => {
              return throwError(apiError);
            })
          )
          .toPromise();
      } catch (error) {}
    }
  
    async getSeminarCount() {
      const getSeminarCountURL = `${apiOf.seminar.getSeminarCount}`;
      try {
        await this.http
          .get(getSeminarCountURL)
          .pipe(
            tap((data: any) => {
              this.resourcesData.push({
                title: 'Seminar</br>& Conferences',
                id: EveryEngDomain.Seminar,
                count: data,
                position: 3,
                background: '#E0D7FF',
                icon:
                  this.aufaitAssetsRoot +
                  '/images/icon/seminar-conference-icon.svg',
              });
              this.resourcesData.sort((a, b) => a.position - b.position);
            }),
            catchError((apiError: ErrorMessage) => {
              return throwError(apiError);
            })
          )
          .toPromise();
      } catch (error) {}
    }
  
    async getKnowCount() {
      const getKnowCountURL = `${apiOf.blog.getBlogCount}`;
      try {
        await this.http
          .get(getKnowCountURL)
          .pipe(
            tap((data: any) => {
              this.resourcesData.push({
                title: 'Article</br>& Blogs',
                id: EveryEngDomain.Story,
                count: data,
                position: 4,
                background: '#F9A8A8',
                icon:
                  this.aufaitAssetsRoot + '/images/icon/articles-blogs-icon.svg',
              });
              this.resourcesData.sort((a, b) => a.position - b.position);
            }),
            catchError((apiError: ErrorMessage) => {
              return throwError(apiError);
            })
          )
          .toPromise();
      } catch (error) {}
    }
  
    async getCommunityCount() {
      this.resourcesData.push({
        title: 'Community</br>& Consultation',
        id: EveryEngDomain.Community,
        position: 5,
        count: 0,
        background: '#EDB9DC',
        icon: this.aufaitAssetsRoot + '/images/icon/communities-icon.svg',
      });
      // const getCommunityCountURL = `${apiOf.community.getCommunityCount}`;
      // try {
      //   await this.http
      //     .get(getCommunityCountURL)
      //     .pipe(
      //       tap((data: any) => {
      //         this.resourcesData.push({
      //           title: 'Communities',
      //           id: this.resourcesData && this.resourcesData.length + 1,
      //           count: data,
      //           icon: this.aufaitAssetsRoot + '/images/icon/communities-icon.svg',
      //         });
      //       }),
      //       catchError((apiError: ErrorMessage) => {
      //         return throwError(apiError);
      //       })
      //     )
      //     .toPromise();
      // } catch (error) {}
    }
  
    async getProjectCount() {
      this.resourcesData.push({
        title: 'Projects for</br>Freelancer',
        id: EveryEngDomain.Projects,
        count: 0,
        position: 6,
        background: '#FEC073',
        icon:
          this.aufaitAssetsRoot +
          '/images/icon/projects-freelancing-icon.svg',
      });
      this.resourcesData.push({
        title: 'Internship</br>Options',
        id: EveryEngDomain.Internship,
        count: 0,
        position: 7,
        background: '#FEC073',
        icon:
          this.aufaitAssetsRoot +
          '/images/icon/projects-freelancing-icon.svg',
      });
      this.resourcesData.push({
        title: 'Jobs in</br>Engineering',
        id: EveryEngDomain.EngineeringJobs,
        count: 0,
        position: 8,
        background: '#FEC073',
        icon:
          this.aufaitAssetsRoot +
          '/images/icon/projects-freelancing-icon.svg',
      });
      this.resourcesData.push({
        title: 'Digital</br>& AI Tools',
        id: EveryEngDomain.Projects,
        count: 0,
        position: 9,
        background: '#FEC073',
        icon:
          this.aufaitAssetsRoot +
          '/images/icon/projects-freelancing-icon.svg',
      });
      this.resourcesData.push({
        title: 'Outsource</br>Services',
        id: EveryEngDomain.Projects,
        count: 0,
        position: 10,
        background: '#FEC073',
        icon:
          this.aufaitAssetsRoot +
          '/images/icon/projects-freelancing-icon.svg',
      });

      this.resourcesData.sort((a, b) => a.position - b.position);

      // const getProjectCountURL = `${apiOf.project.getProjectCount}`;
      // try {
      //   await this.http
      //     .get(getProjectCountURL)
      //     .pipe(
      //       tap((data: any) => {
      //         this.resourcesData.push({
      //           title: 'Projects freelancing',
      //           id: this.resourcesData && this.resourcesData.length + 1,
      //           count: data,
      //           icon:
      //             this.aufaitAssetsRoot +
      //             '/images/icon/projects-freelancing-icon.svg',
      //         });
      //       }),
      //       catchError((apiError: ErrorMessage) => {
      //         return throwError(apiError);
      //       })
      //     )
      //     .toPromise();
      // } catch (error) {}
    }

    routeToSearch(EEdomain: any) {
      if(EEdomain == EveryEngDomain.Projects || EEdomain == EveryEngDomain.Community) {
        this.router.navigateByUrl(`/coming-soon`);
        return;
      }

      if(EEdomain === -1) {
        this.router.navigateByUrl(`/user/dashboard`);
        return;
      }

      this.router.navigateByUrl(`/search?EEdomains=${EEdomain}`);
    }
}
